<template>
  <div class="organization">
    <div class="man-title">
      润滑油管理
      <el-button @click="add()" class="btn" type="primary" size="mini" :disabled="roleId == 3 || roleId == 4">
        {{activeName=='archives'?'创建档案':
          activeName=='brand'?'创建品牌':
          activeName=='classification'?'创建分类':
          activeName=='level'?'创建级别':''}}
      </el-button>
    </div>
    <div class="table-tree">
      <el-tabs v-model="activeName" @tab-click="handleClick" tab-position="left" style="width: 100%;height: 100%;">
        <el-tab-pane label="润滑油档案" name="archives">
          <archives />
        </el-tab-pane>
        <el-tab-pane label="润滑油品牌" name="brand">
          <brand />
        </el-tab-pane>
        <el-tab-pane label="润滑油分类" name="classification">
          <classification />
        </el-tab-pane>
        <el-tab-pane label="润滑油级别" name="level">
          <level />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import archives from './archives/index.vue'
import brand from './brand/index.vue'
import classification from './classification/index.vue'
import level from './level/index.vue'

export default {
  components: {
    archives,
    brand,
    classification,
    level
  },
  data() {
    return {
      activeName: 'archives',
      roleId: 0
    }
  },
  methods: {
    add() {
      if(this.activeName=='archives'){
        this.$router.push('/lubricaOilManage/archives/add')
      }
      if(this.activeName=='brand'){
        this.$router.push('/lubricaOilManage/brand/add')
      }
      if(this.activeName=='classification'){
        this.$router.push('/lubricaOilManage/classification/add')
      }
      if(this.activeName=='level'){
        this.$router.push('/lubricaOilManage/level/add')
      }
    },
    handleClick(tab){
      this.activeName = tab.name
    }
  },
  mounted() {
    this.roleId = sessionStorage.getItem('r')
    if(this.$route.query.active){
      this.activeName = this.$route.query.active
    }else{
      this.activeName = 'archives'
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  float: right;
}
/deep/.el-tabs--left, .el-tabs--right{
    overflow: visible;
}
/deep/.el-tabs__item {
  padding: 0 40px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
}
</style>

<style lang="less">
.organization {
  .el-tree-node__content {
    height: 40px;
  }
}
</style>