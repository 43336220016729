<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="table-list">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="classification" label="分类名称"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-dropdown placement="bottom-start" trigger="click">
                <span @click.stop="">
                  <span class="el-dropdown-link">操作<i class="el-icon-arrow-down"></i></span>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :disabled="roleId == 3 || roleId == 4"><div @click="edit(scope.row)">编辑</div></el-dropdown-item>
                  <el-dropdown-item :disabled="roleId == 3 || roleId == 4"><div @click="dlt(scope.row)">删除</div></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page>
      </div>
    </div>
  </div>
</template>

<script>
import page from "@/components/page.vue";
import dayjs from "dayjs";
export default {
  components: {
    page,
  },
  data() {
    return {
      loading: false,
      size: 50,
      page: 1,
      total: 0,
      tableData: [],
      staffId: 0,
      roleId: 0
    };
  },
  methods: {
    dayjs,
    edit(row) {
      this.$router.push({ name: "classificationEdit", params: { row: row } });
    },
    dlt(row) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("lubeClassificationDelete", {
          id: row.id,
        }).then((res) => {
          this.loadTableData();
        });
      });
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadTableData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadTableData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
    loadTableData() {
      this.loading = true
      this.$ajax.post("lubeClassification", {
        pageNum: this.page,
        pageSize: this.size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.total;
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    }
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadTableData();
    this.staffId = sessionStorage.getItem("id");
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    sessionStorage.removeItem('currentPage')
    sessionStorage.removeItem('currentSize')
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.btn {
  float: right;
}
/deep/.el-tabs--left,
.el-tabs--right {
  overflow: visible;
}
.table-tree {
  display: flex;
  height: ~"calc(100% - 56px)";
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409eff;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.company-name {
  font-size: 18px;
  padding: 10px 0;
  margin: 0 10px;
  line-height: 28px;
  i {
    margin-left: 20px;
    cursor: pointer;
  }
}
.add-box {
  padding: 14px;
  background: #eee;
  margin-bottom: 20px;
  line-height: 28px;
  overflow: hidden;
  border: 1px solid #b1b1b1;
  img {
    vertical-align: text-bottom;
  }
  .btn {
    float: right;
  }
}
</style>

<style lang="less">
.organization {
  .el-tree-node__content {
    height: 40px;
  }
}
</style>